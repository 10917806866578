import restAPI from '@/utils/rest-api'
export default {
    getPromos() {
        return restAPI({
            url: 'promos/current-date',
            method: 'get'
        })
    },
    getPromoBaseOnId(promoId) {
        return restAPI({
            url: `promos/${promoId}/`,
            method: 'get'
        })
    }

}
