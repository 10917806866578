<template>
    <div v-if="theming" class="outer-container">
        <route-head-row
            :title="promoName"
            :is-nested-route="true"
        />
        <div class="img-container">
            <img
                class="promo-img"
                :src="landingImage"
                alt=""
            >
        </div>
        <a
            :style="{color: theming.colors.other.ctaTextColor , background: theming.colors.other.ctaBackgroundColor}"
            class="promo-btn"
            :href="ctaLink"
        >
            {{ ctaName }}
        </a>
        <div class="description-container">
            <div v-dompurify-html="promoDescription" />
        </div>
    </div>
</template>

<script>
    import promosService from '@/services/promos-service'
    import deviceCheck from '@/utils/device-check-utils'
    import RouteHeadRow from '@/components/RouteHeadRow'
    export default {
        components: {
            RouteHeadRow
        },
        data() {
            return {
                localPromo: {},
                deviceType: null
            }
        },
        computed: {
            landingImage() {
                return this.localPromo.landingPromoImage || this?.localPromo?.image
            },
            ctaName() {
                return this.localPromo?.locale?.landingButton
            },
            ctaLink() {
                return this.deviceType ? this.localPromo?.locale?.mobileCtaLink : this.localPromo?.locale?.desktopCtaLink
            },
            promoName() {
                return this.localPromo?.locale?.name
            },
            promoDescription() {
                return this.localPromo?.locale?.longDescription
            },
            currentPoints() {
                return this.$store.getters.getCurrentSession.totalPoints
            },
            currentTier() {
                return this.$store.getters.getCurrentTier
            },
            theming() {
                return this.$store.getters.getTheming
            }
        },
        async created() {
            this.deviceType = deviceCheck.isMobileOrTablet()
            const promo = await promosService.getPromoBaseOnId(this.$route.params.promoId)

            this.localPromo = promo
        }
    }
</script>

<style scoped>
.outer-container {
    position: relative;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.img-container {
    position: relative;
    max-width: 640px;
    margin: 0 auto;
}

.promo-img {
    width: 100%;
    height: auto;
}

.promo-btn {
    margin: 0.5rem auto;
    text-decoration: none;
    min-width: 102px;
    height: 29px;
    text-align: center;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    padding: 0 1rem;
    font-size: 1rem;
    font-weight: 400;
}

.description-container {
    padding: 0.75rem;
    width: 100%;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.7);
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0 auto;
}

.description-header {
    font-size: 1rem;
    font-weight: 700;
}

@media screen and (min-width: 992px) {
    .promo-btn {
        height: 50px;
        display: grid;
        place-items: center;
        margin: 0.75rem auto;
    }

    .description-container {
        max-width: 645px;
        margin: 1rem auto;
    }
}

</style>
